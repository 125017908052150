<template>
  <div class="side">
    <ul>
      <li
        class="sideetel"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
        @click="handleMessage"
      >
        <message-outlined
          :style="{ fontSize: '25px', color: 'currentColor' }"
        />
        <span> 在线留言</span>
      </li>
      <li class="sideetel">
        <i
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-telegram"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
            /></svg></i
        >Telegram
        <div class="telBox son">
          <dd class="bgs1">
            <span v-for="(item, index) in tg" :key="index">
              <a target="_blank" :href="item.href">{{ item.name }}</a>
            </span>
          </dd>
        </div>
      </li>
      <li class="sidetop"><i class="bgs6"></i>返回顶部 <a-back-top /></li>
    </ul>

    <Message ref="MessageRef"></Message>
  </div>
</template>

<script>
import Message from "../message.vue";
import { MessageOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    Message,
    MessageOutlined,
  },
  data() {
    return {
      tg: [],
    };
  },
  mounted() {
    this.queryInfo();
  },
  methods: {
    handleMessage() {
      this.$refs.MessageRef.showModal();
    },
    async queryInfo() {
      // axios.get('https://cms.dingdang.tw/api/landingpage').then(res=>{
      //   this.data = res.data.data
      //   console.log(this.data,'返回数据')
      // })
      let response = await fetch("https://cms.dingdang.tw/api/landingpage");
      let res = await response.json();
      console.log(res);
      this.tg = res.data.attributes.tg;
    },
  },
};
</script>
<style scoped lang="scss">
.side .ant-back-top {
  width: 78px;
  height: 78px;
  right: 0;
  top: 80%;
  margin-top: -44px;
  // opacity: 0!important;
}

.side * {
  margin: 0px;
  padding: 0px;
  font-family: "微软雅黑";
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #686868;
}
img {
  border: 0;
}

.side {
  position: fixed;
  width: 78px;
  right: 0;
  top: 80%;
  margin-top: -200px;
  z-index: 100;
  border: 1px solid #e0e0e0;
  background: #fff;
  border-bottom: 0;
}
.side ul li {
  width: 78px;
  height: 78px;
  float: left;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
}
.side ul li:hover {
  background: #2f4bfb;
  color: #fff;
}
.side ul li:hover a {
  color: #fff;
}
.side ul li i {
  height: 25px;
  margin-bottom: 1px;
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 25px;
  margin-top: 14px;
  transition: all 0.3s;
}
.side ul li i.bgs6 {
  background-image: url(../../assets/right_pic6_on.png);
}
.side ul li .sidebox {
  position: absolute;
  width: 78px;
  height: 78px;
  top: 0;
  right: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.side ul li.sidetop {
  background: #2f4bfb;
  color: #fff;
}
.side ul li.sidetop:hover {
  opacity: 0.8;
  filter: Alpha(opacity=80);
}
.side ul li.sideewm .ewBox.son {
  width: 238px;
  display: none;
  color: #363636;
  text-align: center;
  padding-top: 235px;
  position: absolute;
  left: -240px;
  top: 0;
  // background-image: url(../images/leftewm.png);
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #e0e0e0;
}
.side ul li.sideetel .telBox.son {
  width: 240px;
  height: 78px;
  display: none;
  color: #fff;
  text-align: left;
  position: absolute;
  left: -240px;
  top: 0px;
  background: #2f4bfb;
}
.side ul li.sideetel .telBox dd {
  display: block;
  height: 78px;
  // overflow: hidden;
  line-height: 14px;
  font-size: 18px;
  padding-top: 10px;
  text-align: center;
}
.side ul li.sideetel .telBox dd span {
  display: block;
  line-height: 28px;
  height: 28px;
  overflow: hidden;
  font-size: 18px;
}
.side ul li.sideetel .telBox dd.bgs1 {
  background-color: #1c36d9;
}
.side ul li.sideetel .telBox dd.bgs2 {
  // background: url(../images/right_pic9.png) 28px center no-repeat;
}
.side ul li:hover .son {
  display: block !important;
  animation: fadein 1s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
<style>
.side .ant-back-top-content {
  background-color: rgba(0, 0, 0, 0);
  color: #2f4bfb;
}
.side .ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #2f4bfb;
}
</style>
