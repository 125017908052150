<template>
  <a-modal v-model:visible="visible" title="在线留言" :footer="null">
    <a-form
      :model="formState"
      v-bind="layout"
      name="nest-messages"
      :validate-messages="validateMessages"
      @finish="onFinish"
    >
      <a-form-item :name="['user', 'content']" label="内容">
        <a-textarea v-model:value="formState.user.content" />
      </a-form-item>
      <a-form-item
        :name="['user', 'name']"
        label="联系人"
        :rules="[{ required: true }]"
      >
        <a-input v-model:value="formState.user.name" />
      </a-form-item>
      <a-form-item :name="['user', 'phone']" label="手机号">
        <a-input v-model:value="formState.user.phone" />
      </a-form-item>
      <a-form-item
        :name="['user', 'wechat']"
        label="微信号"
        :rules="[{ required: true }]"
      >
        <a-input v-model:value="formState.user.wechat" />
      </a-form-item>

      <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 4 }">
        <a-button
          :loading="loading"
          type="primary"
          html-type="submit"
          size="large"
          shape="round"
          style="width: 150px"
          >提 交</a-button
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import axios from "axios";
import { message,Form } from "ant-design-vue";
import { defineComponent, reactive, ref } from "vue";
const useForm = Form.useForm;

export default defineComponent({
  setup() {
    const visible = ref(false);
    const loading = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 18,
      },
    };
    const validateMessages = {
      required: "${label} 必填!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };
    const formState = reactive({
      user: {
        name: "",
        phone: "",
        wechat: "",
        content: "",
      },
    });
    const {
      resetFields,
    } = useForm(formState);

    const onFinish = (values) => {
      console.log("Success:", values);
      loading.value = true;
      axios
        .post("https://cms.dingdang.tw/api/messages", {
          data: { ...values.user },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            message.success("你的留言已经收到,我们将会尽快给你回复。");
          } else {
            message.error("提交留言发生错误，请稍后再试。");
          }
          loading.value = false;
          visible.value = false;
          resetFields()
        });
    };
    return {
      formState,
      onFinish,
      layout,
      validateMessages,
      visible,
      loading,
      showModal,
      handleOk,
      resetFields
    };
  },
});
</script>
