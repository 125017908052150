<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
const qs = require("qs");
dayjs.locale("zh-cn");
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted(){
    this.queryList()
  },
  methods:{
    async queryList(){
      const query = qs.stringify(
        {
          populate: '*',
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      let response = await fetch(`https://cms.dingdang.tw/api/homepage?${query}`);
      let res = await response.json();
      console.log(res.data.attributes,'pppppp');
      document.title = res.data.attributes.seo.metaTitle
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.ant-tag-has-color {
  font-size: 12px;
}
</style>
