<template>
  <div class="home">
    <section class="dingdang-bg">
      <!-- logo和导航 -->
      <a-row>
        <a-col
          :xs="{ span: 24 }"
          :lg="{ span: 12 }"
          style="display: flex; flex-direction: column; padding-left: 8%"
        >
          <img
            src="@/assets/logo.png"
            alt=""
            style="width: 390px; height: 94px; margin-left: -22px"
          />
        </a-col>
        <a-col :xs="{ span: 24 }" :lg="{ span: 12 }" style="display: flex">
          <img
            src="@/assets/check.png"
            class="animate__animated animate__bounceIn"
            style="
              width: 222px;
              height: 71px;
              margin-right: 50px;
              cursor: pointer;
            "
          />
          <img
            @click="handleLink"
            src="@/assets/guide.png"
            class="animate__animated animate__bounceIn"
            style="width: 195px; height: 71px; cursor: pointer"
          />
        </a-col>
      </a-row>

      <!-- 文案 -->
      <a-row class="text">
        <a-col
          :xs="{ span: 24 }"
          :lg="{ span: 12 }"
          style="display: flex; flex-direction: column; padding-left: 8%"
        >
          <img
            src="@/assets/1.png"
            class="animate__animated animate__fadeInRight"
            alt=""
            style="width: 673px"
          />
          <img
            src="@/assets/2.png"
            class="animate__animated animate__fadeInRight animate__delay-1s"
            alt=""
            style="width: 673px"
          />
          <img
            src="@/assets/3.png"
            class="animate__animated animate__fadeInRight animate__delay-2s"
            alt=""
            style="width: 673px"
          />
          <img
            src="@/assets/4.png"
            class="animate__animated animate__fadeInRight animate__delay-3s"
            alt=""
            style="width: 673px"
          />
        </a-col>
      </a-row>

      <!-- 下载应用 -->
      <a-row class="download">
        <a-col :span="24">
          <router-link to="/web/download">
            <img
              src="@/assets/download.png"
              class="animate__animated animate__bounceIn"
              alt=""
              style="width: 392px; height: 106px; cursor: pointer"
            />
          </router-link>
        </a-col>
      </a-row>

      <!-- 秒懂叮当助手 -->
      <a-row class="known" data-aos="flip-left">
        <a-col :span="24" class="known-bg">
          <p class="title">秒懂叮当助手（全球号）</p>
          <p class="content">
            叮当助手（全球号）作为全球手机号码智能处理软件，软件具备提供全球精选号段实时更新、预测号码实号比例、全球号码在线自动生成、多样化的号码处理功能，更多个性化定制工具辅助使用。
          </p>
          <p class="content">
            叮当助手（全球号）数据产品矩阵，定位智能数据用户增长，通过对海量号码数据的深度挖掘及分析，拥有专业的智能组合排列算法，为用户提供批量精选号码和个性化定制工具使用，轻松处理千万级别的数据，百万级数据的操作可瞬间完成。
          </p>
        </a-col>
      </a-row>
      <br />
    </section>

    <!-- 叮当助手 -->
    <section class="dingdang-helper">
      <a-row class="dingdang-helper-content">
        <a-col :span="24" style="">
          <div class="solo">
            智能定位
            <a-divider type="vertical" />
            全球检索
            <a-divider type="vertical" />
            专业高效
            <a-divider type="vertical" />
            节省安全
          </div>
          <div class="product">
            <a-row :gutter="[60, 60]">
              <a-col :span="12" v-for="(p, index) in products" :key="index">
                <a-list
                  item-layout="horizontal"
                  :data-source="[p]"
                  data-aos="fade-up-right"
                >
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <a-list-item-meta :description="item.desc">
                        <template #title>
                          <img
                            src="@/assets/tb11.png"
                            v-if="index > 3 && index < 8"
                          />
                          {{ item.name }}
                        </template>
                        <template #avatar>
                          <div :class="item.icon"></div>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </template>
                </a-list>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <br />
    </section>

    <!-- 叮当焦点 -->
    <section>
      <a-row :gutter="[16, 16]" justify="center">
        <cardVue category="focus" :style="{margin:'50px'}" ></cardVue>
      </a-row>
    </section>

    <!-- 叮当案例 -->
    <section>
      <a-row :gutter="[16, 16]" justify="center">
        
          <cardVue category="case" style="" :pageSize="6"></cardVue>
    
      </a-row>
    </section>

    <section class="dingdang-number" data-aos="zoom-in">
      <a-row>
        <a-col :span="24" style="">
          <img
            src="@/assets/number.png"
            alt=""
            style="width: 1010px; height: 1782px"
          />
        </a-col>
      </a-row>
      <br />
    </section>

    <section class="dingdang-star" data-aos="zoom-in">
      <a-row>
        <a-col :span="24" style="">
          <img
            src="@/assets/star.png"
            alt=""
            style="width: 1932px; height: 1196px"
          />
        </a-col>
      </a-row>
      <br />
    </section>

    <section class="dingdang-case" data-aos="fade-up" data-aos-duration="3000">
      <a-row>
        <a-col :span="24" style="">
          <img
            src="@/assets/case.png"
            alt=""
            style="width: 1212px; height: 688px"
          />
        </a-col>
      </a-row>
      <br />
    </section>

    <sideToolVue />
  </div>
</template>

<script>
// @ is an alias to /src
import mobileMixin from "@/mixin/mobile";
import cardVue from "./article/card.vue";
import sideToolVue from "./components/side-tool.vue";
export default {
  name: "Home",
  components: {
    cardVue,
    sideToolVue
  },
  mixins: [mobileMixin],
  data() {
    return {
      products: [
        {
          name: "号码对比工具",
          icon: "tb1",
          sufixIcon: "",
          desc: "快速剔除两个txt文件中的“重复数据”，千万全球数据轻松处理，更支持TXT/VCF数据格式，可分割多个指定数量导出使用。",
        },
        {
          name: "号码生成工具",
          icon: "tb2",
          sufixIcon: "",
          desc: "全球号码在线自动生成，选择国家及手机号码，输入起始号码及生成数量，一键批量自动生成手机号码。",
        },
        {
          name: "号码区分工具",
          icon: "tb3",
          sufixIcon: "",
          desc: "快速分类全球各地区文档号码，并可区分不同国家号码并导出文件",
        },
        {
          name: "精选号段",
          icon: "tb4",
          sufixIcon: "",
          desc: "预测全球号码在线比例，全球号段实时更新，实号空号预测 。",
        },
        {
          name: "号码排序",
          icon: "tb5",
          sufixIcon: "",
          desc: "将导入的千万级别数据，一键按从小到大顺序排列 。",
        },
        {
          name: "号码乱序",
          icon: "tb6",
          sufixIcon: "",
          desc: "一键打乱所有号码规则，不被轻易察觉逻辑顺序，更安全的营销。",
        },
        {
          name: "清除非手机号码",
          icon: "tb7",
          sufixIcon: "",
          desc: "剔除导入的所有非手机格式号码，节约营销成本。",
        },
        {
          name: "TXT/VCF格式导出",
          icon: "tb8",
          sufixIcon: "",
          desc: "处理后的号码可导出TXT或VCF格式，直接导入手机即可使用，方便多种营销模式。",
        },
        {
          name: "地图搜号",
          icon: "tb9",
          sufixIcon: "",
          desc: "全球地图搜索不同国家的拓展标签及关键词，提取下载公开号码。",
        },
        {
          name: "系统防护",
          icon: "tb10",
          sufixIcon: "",
          desc: "数据安全加密处理， 数据不泄漏，不储存。",
        },
      ],
    };
  },
  methods: {
    handleLink() {
      window.open(
        "https://www.yuque.com/docs/share/0651553e-2636-415b-bcf0-49fb6d2bcf20?#%20%E3%80%8A%E5%8F%AE%E5%BD%93%E5%85%A8%E7%90%83%E5%8F%B7%E5%8A%A9%E6%89%8B%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%E3%80%8B",
        "_blank"
      );
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  overflow-x: hidden;
  padding-bottom: 100px;
  background-image: url("../assets/bottom.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}
.dingdang-bg {
  padding-top: 30px;
  height: 1487px;
  background-image: url("../assets/header-bg.png"),
    url("../assets/header-bg2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-position-x: calc(100% + 355px), -150px;

  .text {
    margin-top: 160px;
    img {
      margin-bottom: 60px;
    }
  }

  //下载应用
  .download {
    margin-top: 150px;
  }
  .known {
    margin-top: 50px;
    .known-bg {
      height: 496px;
      padding-top: 80px;

      background-image: url("../assets/known-bg.png");
      background-position: center;
      background-repeat: no-repeat;

      .title {
        font-weight: bold;
        font-size: xx-large;
      }
      .content {
        width: 981px;
        margin: 0 auto;
        font-size: 20px;
        padding: 0 40px;
        text-indent: 30px;
        line-height: 50px;
        text-align: left;
      }
    }
  }
}

.dingdang-helper {
  margin-top: 120px;
  background-color: #3148fa;
  background-image: url("../assets/dingdang.png");
  height: 1295px;
  .dingdang-helper-content {
    background-image: url("../assets/logo-white.png");
    background-position: top center;
    background-position-y: 40px;
    background-repeat: no-repeat;
    height: 1295px;
    overflow: hidden;

    .solo {
      font-size: 24px;
      font-weight: 200;
      color: #fff;
      height: 200px;
      line-height: 380px;
      .ant-divider {
        background: #fff;
        height: 1.2rem;
        width: 3px;
      }
    }

    .product {
      width: 1000px;
      margin: 100px auto;
      text-align: left;
    }
  }
}

.dingdang-number {
  margin-top: 100px;
}
.dingdang-star {
  margin-top: 100px;
  .ant-row {
    background: #3148fa;
    overflow: hidden;
  }
}

.dingdang-case {
  margin-top: 100px;
}

</style>
<style>

.product .ant-list-item-meta-title {
  font-size: 30px;
  display: flex;
  align-items: center;
  color: #fff;
}
.product .ant-list-item-meta-avatar {
  width: 50px;
}
.product .ant-list-item-meta-title img {
  margin-right: 10px;
}
.product .ant-list-item-meta-description {
  color: #ddd;
}
.product .ant-list-item-meta-avatar {
  margin-right: 30px;
  padding-top: 10px;
}
.tb1 {
  width: 41px;
  height: 59px;
  background: url("../assets/tb1.png");
}
.tb2 {
  width: 42px;
  height: 60px;
  background: url("../assets/tb2.png");
}
.tb3 {
  width: 56px;
  height: 56px;
  background: url("../assets/tb3.png");
}
.tb4 {
  width: 51px;
  height: 44px;
  background: url("../assets/tb4.png");
}
.tb5 {
  width: 35px;
  height: 40px;
  background: url("../assets/tb5.png");
}
.tb6 {
  width: 43px;
  height: 43px;
  background: url("../assets/tb6.png");
}
.tb7 {
  width: 54px;
  height: 53px;
  background: url("../assets/tb7.png");
}
.tb8 {
  width: 39px;
  height: 46px;
  background: url("../assets/tb8.png");
}
.tb9 {
  width: 50px;
  height: 46px;
  background: url("../assets/tb9.png");
}
.tb10 {
  width: 38px;
  height: 48px;
  background: url("../assets/tb10.png");
}
</style>
