import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import "ant-design-vue/dist/antd.css";
// import 'animate.css';
import animated from "animate.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// 动画库
AOS.init();
 

const app = createApp(App);

app.use(animated).use(router).use(Antd).mount("#app");
