// 定义一个 mixin 对象
const isMobileMixin = {
  methods: {
    _isMobile() {
      // 判断phone端还是pc端
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag ? true : false;
    },
  },
  mounted() {
    console.log(this._isMobile(), "什么端");
    let pathstr = this.$route.path;
    if (this._isMobile()) {
      //是手机端，需要将页面切换成手机端路由
      let regex = /\/web\/(\S*)/;
      //测试是否匹配，进入则说明路由切换到电脑端
      console.log(pathstr, "手机端路由", regex.test(pathstr));
      if (regex.test(pathstr)) {
        let temp = pathstr.match(regex);
        if (temp != null) {
          pathstr = "/app/" + temp[1];
        }
        // 切换到手机端路由
        console.log(pathstr, "手机端路由1");
        this.$router.replace(pathstr);
      }
    } else {
      //是pc端，需要将页面切换到pc端路由
      let regex = /\/app\/(\S*)/;
      //测试是否匹配，进入则说明路由切换到手机端
      if (regex.test(pathstr)) {
        let temp = pathstr.match(regex);
        if (temp != null) {
          pathstr = "/web/" + temp[1];
        }
        // 切换到pc端路由
        this.$router.replace(pathstr);
      }
    }
  },
};

export default isMobileMixin;
