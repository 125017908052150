import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/web/home",
    meta: {
      title: "叮当助手",
    },
  },

  // pc端
  {
    path: "/web/home",
    name: "Home",
    component: Home,
    meta: {
      title: "叮当助手",
    },
  },

  {
    path: "/web/download",
    name: "Download",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Download.vue"),
    meta: {
      title: "叮当助手-下载应用",
    },
  },

  {
    path: "/web/:category",
    name: "DingdangArticles",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Focus-List.vue"),
    children: [
      {
        path: "list",
        name: "DingdangList",
        component: () => import("../views/article/list.vue"),
        meta: {
          title: "叮当助手-叮当焦点列表",
        },
      },
      {
        path: "detail/:id",
        name: "DingdangDetail",
        component: () => import("../views/article/detail.vue"),
        meta: {
          title: "叮当助手-叮当焦点详情",
        },
      },
    ],
  },

  // {
  //   path: "/web/focus/:id",
  //   name: "DingdangFocus",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Focus.vue"),
  //   meta: {
  //     title: "叮当助手-叮当焦点",
  //   },
  // },

  // app端
  {
    path: "/app/home",
    name: "AppHome",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/app/Home.vue"),
    meta: {
      title: "叮当助手",
    },
  },
  {
    path: "/app/download",
    name: "AppDownload",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/app/Download.vue"),
    meta: {
      title: "叮当助手-下载应用",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// ...

export default router;
