import axios from 'axios'

//自动加在url前面
axios.defaults.baseURL = 'https://cms.dingdang.tw/api'
//超时时间 
axios.defaults.timeout = 120000
//跨域凭证
axios.defaults.withCredentials = false

//响应和请求 拦截器配置
axios.interceptors.request.use(config => {
    console.log(config)
    return config
}, error => {
    Promise.reject(error)
})

// axios.interceptors.response.use(res => {
//     // if (typeof res.data !== 'object') {
//     //     // ElMessage.error('返回数据不是对象！')
//     //     return Promise.reject(res)
//     // }
//     if (res.data.code !== 200 ){
//         // ElMessage.error('返回码不等于200')
//     }
//     return res.data
// }, error => {
//     // ElMessage.error('网络异常')
//     Promise.reject(error)
// })

export default axios
