<template>
  <a-spin :spinning="loading">
    <a-card hoverable class="article-card" :style="style">
      <a-row justify="center" style="width: 100%">
        <div>
          <h1 style="margin: 50px; font-size: 24px; font-weight: bold">
            <a-divider
              type="vertical"
              style="
                margin: 0px 4px;
                border-radius: 20px;
                width: 4px;
                height: 10px;
                background-color: #6172fb;
              "
            />
            <a-divider
              type="vertical"
              style="
                margin: 0px 4px;
                border-radius: 20px;
                width: 4px;
                height: 16px;
                background-color: #6172fb;
              "
            />
            {{ title }}
            <a-divider
              type="vertical"
              style="
                margin: 0px 4px;
                border-radius: 20px;
                width: 4px;
                height: 16px;
                background-color: #6172fb;
              "
            />
            <a-divider
              type="vertical"
              style="
                margin: 0px 4px;
                border-radius: 20px;
                width: 4px;
                height: 10px;
                background-color: #6172fb;
              "
            />
          </h1>
          <a-list
            :grid="{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: category == 'focus' ? 4 : 3,
              xxxl: 2,
            }"
            :data-source="data"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <a-card
                  hoverable
                  :style="{ width: width }"
                  @click="handleClick(item.id)"
                >
                  <template #cover>
                    <img
                      :width="width + 'px'"
                      height="200"
                      alt="example"
                      :src="
                        `https://cms.dingdang.tw` +
                        item.image.data.attributes.url
                      "
                    />
                  </template>
                  <a-card-meta :title="item.title">
                    <template #description>
                      <a-typography-paragraph
                        type="secondary"
                        style="text-align: left;"
                        :ellipsis="{
                          rows: 3,
                        }"
                        :content="item.description"
                      />

                      <p style="text-align: left">
                        {{ dayjs(item.publishedAt).format("YYYY-MM-DD") }}
                      </p>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-list-item>
            </template>
          </a-list>

          <h1 style="text-align: right; margin-top: 50px">
            <router-link :to="`/web/${category}/list`">
              <a-button type="link" size="large"> 更多>></a-button>
            </router-link>
          </h1>
        </div>
      </a-row>
    </a-card>
  </a-spin>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from "vue";
const qs = require("qs");
import dayjs from "dayjs";
import request from "@/utils/request";
import { useRouter } from "vue-router";
const categoryObj = {
  focus: "叮当焦点",
  case: "叮当案例",
};
export default defineComponent({
  props: {
    category: String,
    style: Object,
    height: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 250,
    },
    pageSize: {
      type: Number,
      default: 4,
    },
  },
  setup(props) {
    const data = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const queryList = async (keyword) => {
      loading.value = true;
      const query = qs.stringify(
        {
          sort: ["publishedAt:desc"],
          filters: {
            category: {
              name: {
                $eq: props.category,
              },
            },
            $or: [
              {
                content: {
                  $containsi: keyword || "",
                },
              },
              {
                title: {
                  $containsi: keyword || "",
                },
              },
            ],
          },
          populate: "*",
          //   fields: ["title",],
          pagination: {
            pageSize: props.pageSize,
            page: 1,
          },
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      try {
        const response = await request.get(`/articles?${query}`);
        let _data = response.data.data.map((item) => {
          return { id: item.id, ...item.attributes };
        });
        data.value = _data;
        console.log("文章数据", _data);
        loading.value = false;
      } catch (error) {
        // this.error = error;
      }
    };

    // 点击跳转文章详情
    const handleClick = (id) => {
      router.push({
        path: `/web/${props.category}/detail/${id}`,
      });
    };

    onMounted(() => {
      queryList();
    });

    const title = computed(() => {
      return categoryObj[props.category];
    });
    return {
      data,
      dayjs,
      handleClick,
      title,
      loading,
    };
  },
});
</script>
<style>
.article-card {
  width: 1200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 0px 0px 50px 0px;
}
</style>
